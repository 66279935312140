.image-container {
    position: relative;
    overflow: hidden;
    width: 500px; /* Adjust as needed */
    height: 500px; /* Adjust as needed */
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.quadrant-controls {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
}

.quadrant-controls button,
.polygon-navigation button {
    padding: 5px 10px;
    margin-right: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.quadrant-controls button:hover,
.polygon-navigation button:hover {
    background-color: #0056b3;
}

.polygon-navigation {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.image-container {
    position: relative;
    overflow: hidden;
    width: 100%; /* Make width responsive */
    max-width: 500px; /* Maximum width */
    margin: auto; /* Center align */
    aspect-ratio: 1; /* Maintain aspect ratio (adjust as needed) */
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .image-container {
        max-width: 100%;
        height: auto;
    }
    .polygon-navigation button {
        padding: 5px;
        font-size: 0.8rem; /* Smaller text on smaller screens */
    }
}
