.responsive-image-container {
  max-width: 100%;
  overflow: hidden;
}

.responsive-image {
  width: 100%;
  height: auto;
}

.contact-us-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.enquiry-form label {
  display: block;
  margin-bottom: 5px;
}

.enquiry-form input,
.enquiry-form textarea {
  width: 100%;
  padding: 8px;
  background-color: #D3D3D3; /* Light grey background */
  color: #333; /* Dark grey text for better visibility */
  border: none; /* Optional: removes the border */
  margin-bottom: 20px;
  box-sizing: border-box;
}

.enquiry-form button.submit-button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

.enquiry-form button.submit-button:hover {
  background-color: #0056b3;
}
