.subscription-container {
    background-color: #262626;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.2);
    max-width: 400px;
    margin: 2rem auto;
    color: #fff;
  }
  
  .subscription-title {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .subscription-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .subscribe-button {
    width: 100%;
    background-color: #3498db;
    color: #fff;
  }
  
  .subscribe-button:hover {
    background-color: #2980b9;
  }
  