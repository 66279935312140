.outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #1e1e1e;
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #262626;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #ffffff;
}

.login-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.7rem;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #333;
  color: #fff;
  font-size: 1rem;
}

.login-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.login-button {
  cursor: pointer;
  padding: 0.7rem;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  background-color: #3498db;
  color: #ffffff;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #2980b9;
}

.horizontal-buttons {
  display: flex;
  gap: 0.5rem;
}

.horizontal-buttons button {
  flex: 1; /* Ensures buttons take equal space */
  padding: 0.7rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.secondary-button {
  background-color: #555;
  color: #ffffff;
}

.secondary-button:hover {
  background-color: #666;
}

.reset-message {
  font-size: 0.9rem;
  color: #4CAF50;
  margin-top: 10px;
}

@media (max-width: 480px) {
  .horizontal-buttons {
    flex-direction: column;
  }
}
