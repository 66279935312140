/* MASTERLAYOUT.CSS (FULLY UPDATED AND TESTED SOLUTION) */

body, html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e1e1e;
  color: #ffffff;
  height: 100%;
}

.master-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  background-color: #262626;
  padding: 2px;
  text-align: left; /* changed to left alignment */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* aligns content to the left */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

header h2 {
  margin: 0;
  font-size: 1.2em;
}

header #logo {
  width: 35px;
  height: auto
}

main.content-container {
  flex: 1;
  align-items: center; /* vertical alignment fix */
  justify-content: center; /* horizontal centering */
  padding: 0.8rem;
}

footer {
  background-color: #262626;
  padding: 10px;
  text-align: center;
  color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

nav {
  position: relative;
}

#menuToggle {
  position: fixed;
  top: 1em;
  right: 1em;
  z-index: 1000;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 25px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: block;
  width: 28px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 2px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
              background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
              opacity 0.55s ease;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #7e7373;
}

#menu {
  position: fixed;
  width: 180px;
  margin-left: 60px;
  padding: 50px;
  padding-top: 20px;
  background: #262626;
  list-style-type: none;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  z-index: 999;
}

#menuToggle input:not(:checked) ~ #menu {
  transform: translate(0, 0);
}

#menuToggle input:checked ~ #menu {
  transform: translate(-100%, 0);
}

a:link, a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #007bff;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input, select, button {
  box-sizing: border-box;
}

button:hover {
  opacity: 0.9;
}


header {
  background-color: #262626;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

#logo {
  width: 35px;
  height: auto;
}

nav.nav-menu {
  position: relative;
}

.menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
}

.menu-btn .icon-close {
  display: none;
}

.menu-links {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #333;
  width: 200px;
  padding: 5px 0;
  border-radius: 8px;
  list-style: none;
  margin: 0;
  box-shadow: 0 4px 10px rgba(0,0,0,0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}

.menu-links.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu-links li {
  padding: 10px 20px;
  text-align: right;
}

.menu-links li a,
.menu-links li button {
  color: #fff;
  text-decoration: none;
  background: none;
  border: none;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.menu-links li a:hover,
.menu-links li button:hover {
  color: #1da1f2;
}

.menu-btn.active .icon-open {
  display: none;
}

.menu-btn.active .icon-close {
  display: block;
}
