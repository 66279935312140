/* Use a unique class to scope your styles */
.register-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #1e1e1e;
    padding: 1rem;
  }
  
  .register-page-form {
    background-color: #2e2e2e;
    padding: 2rem;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    border: 1px solid #1e1e1e;
  }
  
  .register-page-form h3 {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #ffffff;
  }
  
  /* Rest of your form styling, scoped to .register-page-form */
  .register-page-form label {
    font-weight: 600;
    color: #cccccc;
  }
  
  .register-page-form .form-control {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    margin:5px;
    border: 1px solid #555;
    border-radius: 4px;
    transition: border-color 0.2s ease-in-out;
    background-color: #3a3a3a;
    color: #ffffff;
    box-sizing: border-box;
  }
  
  .register-page-form .form-control::placeholder {
    color: #888888;
  }
  
  .register-page-form .form-control:focus {
    border-color: #1e1e1e;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(30, 30, 30, 0.5);
  }
  
  .register-page-form .invalid-feedback {
    color: #ff6b6b;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .register-page-form .alert {
    font-size: 0.95rem;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    background-color: #ff6b6b;
    color: #ffffff;
  }
  
  .register-page-form .btn-primary {
    font-size: 1rem;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #1e1e1e;
    color: #ffffff;
    transition: background-color 0.2s ease-in-out;
  }
  
  .register-page-form .btn-primary:hover {
    background-color: #171717;
  }
  
  .register-page-form a {
    color: #4da6ff;
    text-decoration: none;
  }
  
  .register-page-form a:hover {
    text-decoration: underline;
  }
  