/* Container styling */
.home-page {
  background: #1e1e1e;
  color: #fff;
  min-height: 65vh;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Header styling */
.h1 {
  font-size: calc(2rem + 2vw); /* Responsive font sizing */
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
  color: #fff;
  display: inline-block;        /* Ensures background wraps just the text */
  background-color: rgba(0, 0, 0, 0.7); /* Optional: dark background behind text */
  padding: 0.25em 0.5em;        /* Padding to create some breathing room */
}

.header p {
  font-size: calc(1rem + 0.5vw); /* Responsive font sizing for paragraph */
  margin-bottom: 2rem;
}

/* Button container */
.button-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

/* Button styling */
.home-button {
  background: #007bff;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  margin-top:10px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.home-button:hover {
  background: #0069d9;
  transform: scale(1.05);
}

.home-button:active {
  transform: scale(0.98);
}
