/* Container styling for overall layout */
.container {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  background: #fefefe;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0,0,0,0.1);
  font-family: 'Arial', sans-serif;
}

/* Header styling */
.header {
  text-align: center;
  color: #222;
  margin-bottom: 30px;
  font-size: 30px;
}

/* Flex container for the two form panels */
.forms {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

/* Each panel styling with subtle hover animation */
.formPanel {
  flex: 1;
  min-width: 280px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.formPanel:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0,0,0,0.15);
}

/* Label styling for form fields */
.label {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
}

/* Styling for the select dropdown */
.select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 15px;
  background: #fdfdfd;
}

/* Styling for the buttons */
.button {
  width: 100%;
  padding: 12px;
  background: #8884d8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.button:hover {
  background: #1a1a1a;
}

.button:focus {
  outline: none;
}

/* Chart container */
.chartContainer {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

/* Custom legend container */
.customLegend {
  display: flex;
  flex-direction: column; /* Stack the legend items and category filters vertically */
  font-size: 14px;
  margin-bottom: 10px;
}

/* A single row in the legend (e.g. demand/supply items, or category checkboxes) */
.legendRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

/* Each demand/supply legend item */
.legendItem {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

/* The colored square that indicates the bar color */
.legendColor {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 2px;
}

/* The label for category checkboxes */
.legendCheckboxLabel {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}

.legendCheckboxLabel input {
  margin-right: 5px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .forms {
    flex-direction: column;
  }
  
  .formPanel {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .header {
    font-size: 24px;
  }

  /* Legend rows stack if there's not enough space */
  .legendRow {
    flex-wrap: wrap;
  }

  .legendItem, .legendCheckboxLabel {
    margin: 5px;
  }
}
