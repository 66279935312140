/* Ensuring the body takes full width and height, with no margin or padding */
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
}

/* Main container holding both map and chat */
.container {
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 0;
}

/* Map container */
#map {
  flex: 3; /* 75% of the width */
  height: 100%;
}

/* Chat container */
.chat-container {
  flex: 1; /* 25% of the width */
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  padding-right: 100px
}

/* Chat box */
.chat-box {
  flex: 1;
  overflow-y: scroll; /* Enables scrolling when content overflows */
  max-height: 100%; /* Ensures it doesn't exceed its container */
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align messages at the bottom */
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: medium;
}


/* Remove extra bottom margin from the last message */
.chat-box > *:last-child {
  margin-bottom: 0;
}

/* Chat form for input */
.chat-form {
  display: flex;
  padding: 10px;
}

input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px 10px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  box-sizing: border-box;
}

input[type="text"]::placeholder {
  color: #aaa;
}

button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* User message styling */
.user-message {
  text-align: right;
  font-family: 'Times New Roman', Times, serif;
  color: #69aaff;
  margin: 5px 0;
}

/* AI message styling */
.ai-message {
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
  color: #d8dde4;
  margin: 5px 0;
}
/* ChatComponent.css */

/* Ensuring the body takes full width and has no margin or padding */
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Full-width chat container */
.chat-container {
  width: 100%; /* Ensuring the container takes full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Includes padding in width calculation */
}

/* Styles for the chat box */
.chat-box {
  width: 100%; /* Full width for the chat box */
  overflow-y: auto; /* Allowing scroll on y-axis */
  margin-bottom: 5px;
  height: 250px; /* Fixed height or adjustable based on your design */
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size:medium;
}

/* Styles for the input form */
.chat-form {
  width: 100%; /* Ensuring form takes full width */
  display: flex;
}

input[type="text"] {
  flex-grow: 1; /* Input takes all available space except button */
  margin-right: 10px; /* Spacing between input and button */
}

button {
  width: auto;
}

/* ChatComponent.css */

/* Existing styles... */

input[type="text"] {
  flex-grow: 1; /* Input takes all available space except button */
  margin-right: 10px; /* Spacing between input and button */
  background-color: #333; /* Dark background for the input */
  color: #fff; /* Light color for the text */
  border: 1px solid #555; /* Darker border for better visibility */
  padding: 8px 10px; /* Padding for the input */
  font-size: 16px; /* Adjust font size as needed */

  /* Styling the placeholder text */
  ::placeholder {
    color: #aaa; /* Light grey color for placeholder text */
  }
}

.container {
  width: 100%; /* Ensures the container takes the full width */
  display: flex;
  flex-direction: column;
}

#map {
  width: 100%;
}

.chat-container {
  width: 100%; /* Ensures the chat container takes the full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 100px;
}

.chat-form {
  width: 100%; /* Ensures the form takes the full width */

  display: flex;
  justify-content: center;
}

.chat-input-form {
  margin-top:5px;
  width: 100%; /* Ensures the form takes the full width */
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.user-message {
  text-align: right;
  font-family: 'Times New Roman', Times, serif;
  color: #69aaff;
}

.ai-message {
  text-align: left;
  font-family: 'Times New Roman', Times, serif;
  color: #d8dde4;
}

.chat-input-form {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Optional, adjust if you need a narrower input */
  margin: 5px auto; /* Centers form horizontally and gives top margin */
}

.chat-input-form input[type="text"] {
  flex: 1;
  padding: 8px 10px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  box-sizing: border-box;
  margin-right: 10px; /* spacing */
}

.chat-input-form button {
  width: 100px; /* fixed width */
  padding: 8px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  flex-shrink: 0; /* Button maintains width */
}

.chat-input-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Leaflet Controls - Always visible fix */
.leaflet-control-zoom {
  opacity: 1 !important;
  visibility: visible !important;
}

/* Fullscreen control visibility */
.leaflet-control-fullscreen a {
  background-color: #fff !important;
  color: #000 !important;
}

/* If you have additional visibility problems with fullscreen icon */
.leaflet-control-fullscreen {
  opacity: 1 !important;
}
/* Leaflet Zoom Buttons Icons Fix */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font-family: 'Arial', sans-serif !important;
  font-size: 20px !important;
  line-height: 26px !important;
  text-align: center !important;
  background-image: none !important;
}

.leaflet-control-zoom-in::after {
  content: '+' !important;
}

.leaflet-control-zoom-out::after {
  content: '−' !important;
}

/* Fullscreen Icon Fix */
.leaflet-control-fullscreen a {
  font-family: 'Arial', sans-serif !important;
  font-size: 18px !important;
  text-align: center !important;
  line-height: 26px !important;
  background-image: none !important;
}

.leaflet-control-fullscreen a::after {
  content: '⛶' !important; /* fullscreen icon */
}